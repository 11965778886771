import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons';
import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Tooltip, makeStyles } from '@material-ui/core';
import {
  AttachmentInfo,
  AttachmentPreview,
  BoxV2 as Box,
  Button,
  Flex,
  MultimediaList,
  MultimediaUploader,
  TextInput,
} from 'portal-commons';
import React, { ReactNode, useState } from 'react';
import { isValidInput } from '../../../../../utils';
import { generateAndDownloadFile } from '../../../../../utils/download';
import {
  uploadAttachment,
  getAttachment,
  deleteAttachment,
} from '../../../Campaigns/apis';
import { AttachmentFolder } from '../../../Campaigns/enums';
import {
  FILE_EXTENSIONS_TOOLTIP_FOR_VETTING_APPEAL,
  FILE_TYPES_FOR_VETTING_APPEAL,
  MAX_EXPLANATION_LENGTH,
} from '../../constants';
import { isFileSizeExceeded } from '../AssetsUpload/utils';
import UploadErrorDialog from '../UploadErrorDialog';

const useStyles = makeStyles({
  tooltip: {
    '&.MuiTooltip-tooltip': {
      background: '#0091B3',
      color: '#fff',
      fontSize: 12,
      borderRadius: 10,
      maxWidth: 180,
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
});

interface AppealCategory {
  id: string;
  displayName: string;
  description: string;
}
const mockAppealCategories: AppealCategory[] = [
  {
    id: 'logo',
    displayName: 'Brand Logo',
    description: 'Select this category if Lorem ipsum delores',
  },
  // {
  //   id: 'banner',
  //   displayName: 'Brand Banner',
  //   description: 'Select this category if Lorem ipsum delores',
  // },
];

const fileLimitKB = 10 * 1024;

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
const BrandAssetAppealModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const [checkedCategories, setSelectedCategories] = useState<string[]>([]);
  const [appealCategories, setAppealCategories] =
    useState<AppealCategory[]>(mockAppealCategories);
  const [explanation, setExplanation] = useState('');
  const [explanationError, setExplanationError] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentInfo[]>([]);
  const [openError, setOpenError] = useState(false);
  const [errorContent, setErrorContent] = useState<string | ReactNode>('');
  const [previewImage, setPreviewImage] = useState<AttachmentInfo>();

  const handleCategoryChange = (categoryId: string) => {
    if (checkedCategories.includes(categoryId)) {
      setSelectedCategories(
        checkedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories([...checkedCategories, categoryId]);
    }
  };

  const validateExplanation = () => {
    if (explanation.length > MAX_EXPLANATION_LENGTH) {
      setExplanationError(`Maximum ${MAX_EXPLANATION_LENGTH} chars`);
      return false;
    } else if (!isValidInput(explanation)) {
      setExplanationError('Invalid input');
      return false;
    }
    setExplanationError('');
    return true;
  };

  const handleExplanationChange = (value: string) => {
    setExplanation(value);
    setExplanationError('');
  };

  const handleExplanationBlur = () => {
    validateExplanation();
  };

  const openErrorDialog = (content: string | ReactNode) => {
    setListLoading(false);
    setOpenError(true);
    setErrorContent(content);
  };

  const handleFileTypeError = () => {
    openErrorDialog('The uploaded file type is not supported');
  };

  const onFileChange = async (file: File) => {
    setListLoading(true);
    if (isFileSizeExceeded(file, fileLimitKB)) {
      openErrorDialog('The uploaded file size exceeds the limit');
      return;
    }

    const attachment = await uploadAttachment(
      AttachmentFolder.VettingAppeal,
      file
    );
    if (attachment) {
      setAttachments([
        ...attachments,
        {
          ...attachment,
          file,
        },
      ]);
    }
    setListLoading(false);
  };

  const handleDownload = async (index: number) => {
    setListLoading(true);
    const data = attachments[index];
    if (data.file) {
      generateAndDownloadFile(data.file, data.fileName);
    } else {
      const response = await getAttachment(data.uuid);
      if (response) {
        const file = new File([response], data.fileName);
        generateAndDownloadFile(file);
      }
    }
    setListLoading(false);
  };

  const handlePreview = (index: number) => {
    setPreviewImage(attachments[index]);
  };

  const handleDelete = async (index: number) => {
    setListLoading(true);
    const attachment = attachments[index];
    try {
      const result = await deleteAttachment(attachment.uuid);
      if (result) {
        setAttachments(attachments.filter((_, i) => i !== index));
      }
    } finally {
      setListLoading(false);
    }
  };

  const customAdditionalBlock = () => {
    return (
      <Flex
        sx={{
          fontSize: 'H200',
          lineHeight: '14px',
          textAlign: 'center',
          color: 't.grey300',
          flexDirection: 'column',
          gap: 'xxxs',
        }}
      >
        {/* <span>Maximum 10 uploads</span> */}
        <span>Maximum file size: 10 MB</span>
        <span>Maximum upload size: 30 MB</span>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={FILE_EXTENSIONS_TOOLTIP_FOR_VETTING_APPEAL}
          placement="right"
        >
          <span
            style={{
              textDecoration: 'underline',
              color: '#949495',
              cursor: 'pointer',
            }}
          >
            Approved file types
          </span>
        </Tooltip>
      </Flex>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        aria-labelledby="upload file error"
        data-testid="uploadErrorDialog"
      >
        <Box sx={{ p: '28px 30px 8px', width: '700px' }}>
          <Flex sx={{ flexDirection: 'column', gap: 'xs' }}>
            <Box
              as="h3"
              sx={{
                fontSize: 'H600',
                fontWeight: 600,
                lineHeight: 'normal',
                color: 't.black80',
                my: 's',
                textAlign: 'center',
              }}
            >
              Brand Asset Appeal Request
            </Box>
            <Box sx={{ mb: 'xs', fontSize: 'H400', lineHeight: 'normal' }}>
              You can submit an appeal up to 45 days after the completion of a
              vet. Appeals are charged $10 regardless of the outcome.
            </Box>
            <Box sx={{ mt: 'xs', color: 't.black80' }}>
              <Box as="h4" sx={{ fontSize: 'H500', fontWeight: 600, mb: 4 }}>
                Appeal Categories
              </Box>
              <Box sx={{ fontSize: 'H200' }}>
                Select one or more categories for the appeal.
              </Box>
            </Box>
            {appealCategories.map((category) => (
              <AppealCategory
                key={category.id}
                title={category.displayName}
                message={category.description}
                checked={checkedCategories.includes(category.id)}
                onValueChange={() => handleCategoryChange(category.id)}
              />
            ))}
            <TextInput
              label="Explanation"
              placeholder="Provide a detailed explanation"
              maxLength={MAX_EXPLANATION_LENGTH}
              value={explanation}
              error={!!explanationError}
              note={explanationError}
              onChange={handleExplanationChange}
              onBlur={handleExplanationBlur}
              style={{ margin: '12px 0' }}
              data-testid="brandAssetAppealExplanation"
            />
            <MultimediaUploader
              data-testid="brandAssetAppealUploader"
              onChange={onFileChange}
              onTypeError={handleFileTypeError}
              supportedMimeTypes={FILE_TYPES_FOR_VETTING_APPEAL}
              icon={<FontAwesomeIcon icon={faArrowUpFromBracket} size="2x" />}
              description={
                <Box sx={{ color: 't.grey400' }}>
                  Drop files to attach or...
                </Box>
              }
              additional={customAdditionalBlock()}
              style={{
                minHeight: '220px',
                padding: 30,
                justifyContent: 'center',
                gap: '10px',
                border: '1px dashed #949495',
                borderRadius: '5px',
              }}
            />
            <MultimediaList
              title="Sample Multimedia Files"
              emptyMessage="No files have been attached"
              editable
              attachments={attachments}
              onDownload={handleDownload}
              onSelect={handlePreview}
              onDelete={handleDelete}
              loading={listLoading}
              data-testid="brandAssetAppealAttachments"
              style={{ minHeight: 160 }}
            />
            {previewImage && previewImage.file && (
              <AttachmentPreview
                onClose={() => setPreviewImage(undefined)}
                file={previewImage.file}
                mimeType={previewImage.mimeType}
                style={{ zIndex: 1010 }}
              />
            )}
          </Flex>
          <Flex sx={{ py: 'l', justifyContent: 'center', gap: 18 }}>
            <Button
              onClick={onClose}
              color="primary"
              variant="outline"
              data-testid="brandAssetAppealModalCancel"
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              data-testid="brandAssetAppealModalSubmit"
            >
              Submit Appeal
            </Button>
          </Flex>
        </Box>
      </Dialog>
      <UploadErrorDialog
        open={openError}
        content={errorContent}
        onClose={() => setOpenError(false)}
      />
    </>
  );
};

interface AppealCategoryProps {
  title: string;
  message: string;
  disabled?: boolean;
  checked: boolean;
  onValueChange: (id: string) => void;
}
const AppealCategory: React.FC<AppealCategoryProps> = ({
  title,
  message,
  disabled,
  checked,
  onValueChange,
}) => {
  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        gap: 'xs',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={() => !disabled && onValueChange('logo')}
      data-testid={`appealCategory-${title.replace(/\s/g, '')}`}
    >
      <FontAwesomeIcon
        icon={checked ? faSquareCheck : faSquare}
        size="lg"
        style={{ color: disabled ? '#CCCFD0' : '#4D5659' }}
      />
      <Flex
        sx={{ flexDirection: 'column', gap: 'xs', opacity: disabled ? 0.5 : 1 }}
      >
        <Box sx={{ fontSize: 'H400' }}>{title}</Box>
        <Box sx={{ fontSize: 'H300' }}>{message}</Box>
      </Flex>
    </Flex>
  );
};

export default BrandAssetAppealModal;
