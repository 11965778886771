import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface BrandAssetsBackButtonProps {
  link: string;
}

const BrandAssetsBackButton: FunctionComponent<BrandAssetsBackButtonProps> = ({
  link,
}) => {
  const history = useHistory();

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#666E71',
        cursor: 'pointer',
      }}
      onClick={() => history.push(link)}
      data-testid="brandAssetsBackButton"
    >
      <FontAwesomeIcon icon={faAngleLeft} size="lg" />
      <Box>Back to Brand</Box>
    </Flex>
  );
};

export default BrandAssetsBackButton;
