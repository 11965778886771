import { BrandDetail, ExtVettingRecord } from './types';
import { EntityTypes } from '../../../shared_elements/enums';

export const isValidForPoliticalVet = (
  entityType: string,
  einIssuingCountry: string
) =>
  (entityType === 'SOLE_PROPRIETOR' || entityType === 'NON_PROFIT') &&
  einIssuingCountry === 'US';

export const isValidForWMC = (entityType: string, einIssuingCountry: string) =>
  (entityType === 'PRIVATE_PROFIT' || entityType === 'PUBLIC_PROFIT') &&
  einIssuingCountry === 'US';

export const isValidForAppeal = (record: ExtVettingRecord) =>
  (record.evpUid === 'AEGIS' || record.evpUid === 'WMC') &&
  record.vettingStatus.toLowerCase() !== 'expired';

export const isValidForOperation = (role: string) =>
  ['ROLE_MANAGER', 'ROLE_USER'].includes(role);

export const isValidForIdentityStatusChange = (brand: BrandDetail) =>
  brand.entityType !== EntityTypes.PubliclyTradedCompany ||
  !!brand.businessContactEmail;

export const capitalizeFirstLetter = (str: string): string => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};
