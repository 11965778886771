import { BoxV2 as Box } from 'portal-commons';
import { FunctionComponent } from 'react';

const BrandAssetRow: FunctionComponent = ({ children }) => (
  <Box
    sx={{
      display: 'contents',
      '> *': {
        alignItems: 'center',
        paddingLeft: '16px',
      },
    }}
  >
    {children}
  </Box>
);

export default BrandAssetRow;
