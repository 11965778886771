import { MimeTypes } from 'portal-commons';

export const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
      URL.revokeObjectURL(img.src);
    };
    img.onerror = () => {
      reject(new Error('Failed to load image'));
      URL.revokeObjectURL(img.src);
    };
    img.src = URL.createObjectURL(file);
  });
};

export const isFileSizeExceeded = (file: File, fileLimitKB: number) => {
  return file.size > fileLimitKB * 1024;
};

export const isFileTypeUnSupport = (
  file: File,
  acceptedMimeTypes: MimeTypes
) => {
  return !Object.values(acceptedMimeTypes).includes(file.type);
};

export const isDimensionsNotMatch = (
  image: { width: number; height: number },
  dimensions: { width: number; height: number }
) => {
  return image.width !== dimensions.width || image.height !== dimensions.height;
};

export const isFileNameInvalid = (fileName: string) => {
  if (fileName.length < 3 || fileName.length > 50) {
    return true;
  }
  const validNameRegex = /^[a-zA-Z0-9\s\-_]+$/;
  return !validNameRegex.test(fileName);
};
