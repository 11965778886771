import { BrandAsset, BrandAssetStatus, BrandAssetType } from '../types';

export const Logos: BrandAsset[] = [
  {
    name: 'Logo_Black and White',
    type: BrandAssetType.Logo,
    attachmentUuid: 'LOGO0',
    vettingStatus: BrandAssetStatus.Pending,
  },
  {
    name: 'Logo_Small',
    type: BrandAssetType.Logo,
    attachmentUuid: 'LOGO1',
    vettingStatus: BrandAssetStatus.Unverified,
  },
  {
    name: 'Logo_Black and White',
    type: BrandAssetType.Logo,
    attachmentUuid: 'LOGO2',
    vettingStatus: BrandAssetStatus.Pending,
  },
  {
    name: 'Logo_Small',
    type: BrandAssetType.Logo,
    attachmentUuid: 'LOGO3',
    vettingStatus: BrandAssetStatus.Verified,
  },
  {
    name: 'Logo_Black and White',
    type: BrandAssetType.Logo,
    attachmentUuid: 'LOGO4',
    vettingStatus: BrandAssetStatus.Pending,
  },
  {
    name: 'Logo_Small',
    type: BrandAssetType.Logo,
    attachmentUuid: 'LOGO5',
    vettingStatus: BrandAssetStatus.Unverified,
  },
];

export const Banners: BrandAsset[] = [
  {
    name: 'Banner_Black and White',
    type: BrandAssetType.Banner,
    attachmentUuid: 'BANNER0',
    vettingStatus: BrandAssetStatus.Pending,
  },
  {
    name: 'Banner_Small',
    type: BrandAssetType.Banner,
    attachmentUuid: 'BANNER1',
    vettingStatus: BrandAssetStatus.Unverified,
  },
  {
    name: 'Banner_Black and White',
    type: BrandAssetType.Banner,
    attachmentUuid: 'BANNER2',
    vettingStatus: BrandAssetStatus.Pending,
  },
  {
    name: 'Banner_Small',
    type: BrandAssetType.Banner,
    attachmentUuid: 'BANNER3',
    vettingStatus: BrandAssetStatus.Verified,
  },
];
