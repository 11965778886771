export const MY_CAMPAIGNS_PATH = '/campaigns';
export const MY_CAMPAIGN_DETAILS_PATH = `${MY_CAMPAIGNS_PATH}/:id`;

export const CNP_CAMPAIGNS_PATH = '/cnp-campaigns';
export const CNP_CAMPAIGNS_HISTORY_PATH = `${CNP_CAMPAIGNS_PATH}/history`;
export const CNP_CAMPAIGN_DETAILS_PATH = `${CNP_CAMPAIGNS_PATH}/:id`;

export const MY_CAMPAIGNS_DEFAULT_ENTRY = `${MY_CAMPAIGNS_PATH}?status=ACTIVE`;
export const CNP_CAMPAIGNS_DEFAULT_ENTRY = `${CNP_CAMPAIGNS_PATH}?status=ACTIVE&provisional=false`;
export const CNP_CAMPAIGNS_HISTORY_DEFAULT_ENTRY = `${CNP_CAMPAIGNS_HISTORY_PATH}?status=ACTIVE&provisional=false`;
