import { Flex } from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import BrandAssetsBackButton from '../components/BrandAssetsBackButton';
import BrandAssetsEmulatorBlock from '../components/BrandAssetsEmulatorBlock';
import { BrandAsset } from '../types';

import { Banners, Logos } from '../__mocks__/asset';

const BrandAssets: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [logos, setLogos] = useState<BrandAsset[]>([]);
  const [banners, setBanners] = useState<BrandAsset[]>([]);

  useEffect(() => {
    // TODO: Use API to fetch brand assets instead
    setLogos(Logos);
    setBanners(Banners);
  }, []);

  return (
    <Flex sx={{ flexDirection: 'column', gap: 17 }}>
      <BrandAssetsBackButton link={`/brands/${id}`} />
      <BrandAssetsEmulatorBlock logos={logos} banners={banners} />
    </Flex>
  );
};

export default BrandAssets;
