import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent, useRef } from 'react';

import BrandAssetList, {
  BrandAssetListHeader,
  BrandAssetRow,
} from '../BrandAssetList';
import { BrandAsset } from '../../types';

const Headers: BrandAssetListHeader[] = [
  {
    id: 'name',
    label: 'BANNER',
  },
];

interface BrandBannerListProps {
  banners: BrandAsset[];
  onSelect?: (index: number) => void;
}

interface RowProps {
  banner: BrandAsset;
  onSelect?: () => void;
}

const Row: FunctionComponent<RowProps> = ({ banner, onSelect }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleNameClick = () => {
    inputRef.current?.click();
  };

  return (
    <BrandAssetRow>
      <Flex
        sx={{
          columnGap: '7px',
          color: '#0091B3',
          fontSize: '14px',
          lineHeight: '16px',
        }}
      >
        <input
          ref={inputRef}
          style={{ accentColor: '#D41C62', cursor: 'pointer' }}
          type="radio"
          name="banner"
          onClick={onSelect}
        />
        <Box sx={{ cursor: 'pointer' }} onClick={handleNameClick}>
          {banner.name}
        </Box>
      </Flex>
    </BrandAssetRow>
  );
};

const BrandBannerList: FunctionComponent<BrandBannerListProps> = ({
  banners,
  onSelect,
}) => {
  return (
    <BrandAssetList headers={Headers}>
      {banners.map((banner, index) => (
        <Row
          key={banner.attachmentUuid}
          banner={banner}
          onSelect={() => onSelect?.(index)}
        />
      ))}
    </BrandAssetList>
  );
};

export default BrandBannerList;
