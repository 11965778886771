import {
  globalDeleteService,
  globalGetService,
  globalPostService,
} from '../../../utils/globalApiServices';
import { Pagination } from '../../../utils/types';
import { getAttachment } from '../Campaigns/apis';
import { MOCK_DATA } from './components/BrandAssets/mockData';
import {
  BrandAsset,
  BrandAssetAppeal,
  BrandAssetExtended,
  BrandDetail,
  ExtVettingRecord,
} from './types';

export const getVettingRecords = async (brandUid: string) => {
  const response = await globalGetService(`csp/brands/${brandUid}/vettings`, {
    vettingStatus: 'ACTIVE,PENDING,UNSCORE,FAILED,EXPIRED',
  });
  if (response.ok) {
    return response.data as ExtVettingRecord[];
  }
  return [];
};

export const resend2faEmail = async (brandUid: string) => {
  const response = await globalPostService(`csp/brands/${brandUid}/2faEmail`);
  if (response.ok) {
    return brandUid;
  }
};

export const getBrands = async (query = {}) => {
  const response = await globalGetService('csp/brands', query);
  return response?.ok ? (response.data as Pagination<BrandDetail>) : undefined;
};

export const getBrandIdentityStatus = async () => {
  const response = await globalGetService('enums/brandIdentityStatus');
  return response.ok ? (response.data as string[]) : [];
};

export const getAttachmentAsImageFile = async (data: BrandAssetExtended) => {
  const attachment = await getAttachment(data.attachmentUuid);
  if (attachment) {
    return new File([attachment], data.name, {
      type: data.mimeType,
    });
  }
  return undefined;
};

export const registerBrandAsset = async (
  brandId: string,
  payload: { name: string; type: string; asset: File }
): Promise<
  { name: string; type: string; attachmentUuid: string } | undefined
> => {
  // TODO: Replace it when the API is ready
  // const response = await globalPostService(
  //   `csp/brands/${brandId}/assets`,
  //   payload
  // );
  // return response?.ok ? response.data : undefined;

  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('POST', `csp/brands/${brandId}/assets`, payload);
      resolve({
        name: payload.name,
        type: payload.type,
        attachmentUuid: Date.now().toString(),
      });
    }, 500);
  });
};

export const deleteBrandAsset = async (
  brandId: string,
  name: string
): Promise<boolean> => {
  // TODO: Replace it when the API is ready
  // const response = await globalDeleteService(
  //   `csp/brands/${brandId}/asset/${name}`
  // );
  // return response?.ok || false;

  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('DELETE', `csp/brands/${brandId}/asset/${name}`);
      resolve(true);
    }, 500);
  });
};

export const getBrandAssets = async (
  brandId: string,
  query = {}
): Promise<Pagination<BrandAsset> | undefined> => {
  // TODO: Replace it when the API is ready
  // const response = await globalGetService(
  //   `csp/brands/${brandId}/assets`,
  //   query
  // );
  // return response?.ok ? (response.data as Pagination<BrandAsset>) : undefined;

  return new Promise((resolve) => {
    const responseData: Pagination<BrandAsset> = {
      totalRecords: MOCK_DATA.length,
      records: MOCK_DATA,
      page: 1,
      recordsPerPage: 10,
    };
    setTimeout(() => {
      console.log('GET', `csp/brands/${brandId}/assets`, query);
      resolve(responseData);
    }, 500);
  });
};

export const verifyBrandAsset = async (
  brandId: string,
  name: string
): Promise<boolean> => {
  // TODO: Replace it when the API is ready
  // const response = await globalPostService(
  //   `csp/brands/${brandId}/asset/${name}/verify`
  // );
  // return response?.ok || false;

  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('POST', `csp/brands/${brandId}/asset/${name}/verify`);
      resolve(true);
    }, 500);
  });
};

export const appealBrandAsset = async (
  brandId: string,
  name: string,
  payload: {
    appealCategories: string[];
    attachmentUuidList: string[];
    explanation: string;
  }
): Promise<boolean> => {
  // TODO: Replace it when the API is ready
  // const response = await globalPostService(
  //   `csp/brands/${brandId}/asset/${name}/appeal`,
  //   payload
  // );
  // return response?.ok || false;

  return new Promise((resolve) => {
    setTimeout(() => {
      console.log(
        'POST',
        `csp/brands/${brandId}/asset/${name}/appeal`,
        payload
      );
      resolve(true);
    }, 500);
  });
};

export const getBrandAssetAppeals = async (
  brandId: string,
  name: string,
  query = {}
): Promise<Pagination<BrandAssetAppeal> | undefined> => {
  // TODO: Replace it when the API is ready
  // const response = await globalGetService(
  //   `csp/brands/${brandId}/asset/${name}/appeals`,
  //   query
  // );
  // return response?.ok
  //   ? (response.data as Pagination<BrandAssetAppeal>)
  //   : undefined;

  return new Promise((resolve) => {
    const responseData: Pagination<BrandAssetAppeal> = {
      totalRecords: 0,
      records: [],
      page: 1,
      recordsPerPage: 10,
    };
    setTimeout(() => {
      console.log('GET', `csp/brands/${brandId}/asset/${name}/appeals`, query);
      resolve(responseData);
    }, 500);
  });
};
