import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';

import { BrandAssetListHeader } from './types';

interface BrandAssetListProps {
  headers: BrandAssetListHeader[];
}

const BrandAssetList: FunctionComponent<BrandAssetListProps> = ({
  headers,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoRows: '38px',
        gridTemplateColumns: headers
          .map((header) =>
            header.width ? `minmax(${header.width}px, min-content)` : '1fr'
          )
          .join(' '),
        minHeight: '228px',
        maxHeight: '228px',
        border: '1px solid #C0CCD4',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#333E41',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'contents',
          '> *:first-child': {
            borderTopLeftRadius: '10px',
          },
          '> *:last-child': {
            borderTopRightRadius: '10px',
          },
        }}
      >
        {headers.map((header) => (
          <Flex
            key={header.id}
            sx={{
              position: 'sticky',
              top: 0,
              alignItems: 'center',
              paddingLeft: '16px',
              color: '#00667D',
              background: '#E5EFF2',
              fontWeight: 800,
            }}
          >
            {header.label}
          </Flex>
        ))}
      </Box>
      <Box
        sx={{
          display: 'contents',
          '> *:nth-child(even)': {
            '> *': {
              background: '#F2F3F3',
            },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BrandAssetList;
