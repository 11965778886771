import React, { ReactNode } from 'react';
import { Dialog } from '@material-ui/core';
import { BoxV2 as Box, Flex, Button } from 'portal-commons';

type Props = {
  open: boolean;
  content: string | ReactNode;
  onClose: () => void;
};

const UploadErrorDialog: React.FC<Props> = ({ open, content, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="upload file error"
      data-testid="uploadErrorDialog"
    >
      <Box sx={{ p: '40px 30px 8px', width: '465px' }}>
        <Box
          sx={{
            color: 't.grey500',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 'normal',
          }}
        >
          {content}
        </Box>
        <Flex sx={{ py: 'l', justifyContent: 'center' }}>
          <Button onClick={onClose} color="primary">
            OK
          </Button>
        </Flex>
      </Box>
    </Dialog>
  );
};

export default UploadErrorDialog;
