import React, { useState } from 'react';
import {
  AttachmentPreview,
  BoxV2 as Box,
  Button,
  FilterType,
  Flex,
  RoleGuard,
  Table,
  TableFilter,
  useFilters,
  useTable,
  usePermission,
} from 'portal-commons';
import BrandAssetsListingRow from './BrandAssetsListingRow';
import AssetActionModal, { AssetActionModalType } from './AssetActionModal';
import BrandAssetAppealModal from './BrandAssetAppealModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faGameConsoleHandheld,
} from '@fortawesome/pro-regular-svg-icons';
import {
  BrandAssetExtended,
  BrandAssetStatus,
  BrandAssetType,
} from '../../types';
import { getAttachmentAsImageFile, getBrandAssets } from '../../apis';
import { useParams } from 'react-router-dom';
import { toastFlashMessage } from '../../../../../utils';

const assetTypeOptions = Object.entries(BrandAssetType).map(
  ([label, value]) => ({
    label,
    value,
  })
);
const assetStatusOptions = Object.entries(BrandAssetStatus).map(
  ([label, value]) => ({
    label,
    value,
  })
);

const FILTER_CONFIG = {
  assetType: {
    type: FilterType.Dropdown,
    label: 'Asset Type',
    placeholder: 'Select asset type',
    options: assetTypeOptions,
  },
  status: {
    type: FilterType.Dropdown,
    label: 'Status',
    placeholder: 'Select status',
    options: assetStatusOptions,
  },
  // usedOnRcsAgent: {
  //   type: FilterType.Dropdown,
  //   label: 'Used on RCS Agent',
  //   placeholder: 'Select RCS Agent',
  //   options: [
  //     { label: 'Yes', value: 'true' },
  //     { label: 'N/A', value: 'false' },
  //   ],
  //   width: 200,
  // },
};

const TABLE_COLUMNS = [
  { id: 'name', label: 'ASSET NAME', sortable: false },
  { id: 'type', label: 'ASSET TYPE', sortable: true },
  { id: 'status', label: 'STATUS', sortable: true },
  { id: 'createDate', label: 'CREATION DATE', sortable: true },
  { id: 'expireDate', label: 'EXPIRATION DATE', sortable: true },
  { id: 'action', label: 'ACTION', sortable: false },
];

interface Props {
  onOpenEmulator: () => void;
}
const BrandAssetsList: React.FC<Props> = ({ onOpenEmulator }) => {
  const { id: brandId } = useParams<{ id: string }>();
  const [filtersShown, setFiltersShown] = useState(false);
  const [brandAssets, setBrandAssets] = useState<BrandAssetExtended[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<BrandAssetExtended>();
  const [previewFile, setPreviewFile] = useState<File | null>(null);
  const [previewIndex, setPreviewIndex] = useState<number>();
  const [actionModal, setActionModal] = useState<AssetActionModalType>();
  const [openAppealModal, setOpenAppealModal] = useState(false);
  const { hasPermission } = usePermission();

  const filter = useFilters({ configs: FILTER_CONFIG });
  const table = useTable<BrandAssetExtended>(
    async (props) => {
      const data = await getBrandAssets(brandId, {
        ...filter.appliedFilters,
        ...props,
      });
      let newBrandAssets: BrandAssetExtended[] = [];
      if (data) {
        newBrandAssets = data.records.map((asset) => {
          const existingAsset = brandAssets.find(
            (oldAsset) => oldAsset.attachmentUuid === asset.attachmentUuid
          );
          return {
            ...asset,
            file: existingAsset ? existingAsset.file : undefined,
          };
        });
      }
      setBrandAssets(newBrandAssets);
      return {
        data: data?.records ?? [],
        rowsPerPage: data?.recordsPerPage ?? 10,
        total: data?.totalRecords ?? 0,
        page: data?.page ? +data.page : 1,
      };
    },
    { deps: [filter.appliedFilters], inMemory: true }
  );

  const updateBrandAssets = (attachmentUuid: string, file: File) => {
    setBrandAssets((prev) =>
      prev.map((asset) =>
        asset.attachmentUuid === attachmentUuid ? { ...asset, file } : asset
      )
    );
  };

  const handlePreview = async (data: BrandAssetExtended, idx: number) => {
    setPreviewIndex(idx);
    if (data.file) {
      setPreviewFile(data.file);
    } else {
      const file = await getAttachmentAsImageFile(data);
      if (file) {
        setPreviewFile(file);
        updateBrandAssets(data.attachmentUuid, file);
      }
    }
    setPreviewIndex(undefined);
  };

  const handleAssetActionClose = (data: BrandAssetExtended) => {
    data.file && updateBrandAssets(data.attachmentUuid, data.file);
    setActionModal(undefined);
    setSelectedAsset(undefined);
  };

  const handleAssetActionSubmit = (data: BrandAssetExtended) => {
    data.file && updateBrandAssets(data.attachmentUuid, data.file);
    if (actionModal === 'verify') {
      // todo : integrate API
      toastFlashMessage(
        `${data.name} asset successfully submitted for verification`,
        'success'
      );
    } else {
      // todo : integrate API
      toastFlashMessage(`${data.name} asset deleted successfully`, 'success');
    }
    setActionModal(undefined);
    setSelectedAsset(undefined);
  };

  const handleSubmitAppeal = () => {
    // todo: integrate API
    toastFlashMessage(
      'Brand asset appeal request successfully submitted',
      'success'
    );
    setOpenAppealModal(false);
  };

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 's',
          mb: 'xs',
        }}
      >
        <Button
          size="small"
          color="secondary"
          onClick={() => setFiltersShown(!filtersShown)}
          data-testid="brandAssetsFilterButton"
        >
          <FontAwesomeIcon icon={faFilter} />
          {filtersShown ? 'Hide' : 'Show'} Filters
        </Button>
        <RoleGuard feature="brandDetail.emulatorPreview">
          <Button
            size="small"
            color="secondary"
            onClick={onOpenEmulator}
            data-testid="brandAssetsEmulatorButton"
          >
            <FontAwesomeIcon icon={faGameConsoleHandheld} />
            Emulator
          </Button>
        </RoleGuard>
      </Flex>
      {filtersShown && (
        <Box
          sx={{
            mt: 's',
            mb: 'xs',
            '& .MuiAutocomplete-root': { width: 'initial' },
          }}
        >
          <TableFilter
            configs={FILTER_CONFIG}
            candidateValues={filter.candidateFilters}
            appliedValues={filter.appliedFilters}
            onAppliedValuesChange={filter.handleApply}
            onCandidateValuesChange={filter.handleEdit}
            data-testid="brandAssetsFilter"
          />
        </Box>
      )}
      <Table
        testId="brandAssetsTable"
        loading={table.loading}
        headRows={
          hasPermission('brandDetail.handleBrandAssets')
            ? TABLE_COLUMNS
            : TABLE_COLUMNS.filter((col) => col.id !== 'action')
        }
        disableHover
        tableData={brandAssets.map((asset, idx) => (
          <BrandAssetsListingRow
            key={`${idx}_${asset.attachmentUuid}`}
            data={asset}
            loading={previewIndex === idx}
            onPreview={() => {
              setSelectedAsset(asset);
              handlePreview(asset, idx);
            }}
            onVerify={() => {
              setSelectedAsset(asset);
              setActionModal('verify');
            }}
            onDelete={() => {
              setSelectedAsset(asset);
              setActionModal('delete');
            }}
            onAppeal={() => {
              setSelectedAsset(asset);
              setOpenAppealModal(true);
              console.log('appeal');
            }}
          />
        ))}
        filter={table.filter}
        handleChangePage={table.handleChangePage}
        createSortHandler={table.handleSorting}
        pagination={table.pagination}
        emptyState="no assets to view"
        emptyBlockHeight="180px"
      />
      {previewFile && (
        <AttachmentPreview
          file={previewFile}
          onClose={() => setPreviewFile(null)}
          mimeType={previewFile.type}
          style={{ zIndex: 1000 }}
        />
      )}
      {selectedAsset && actionModal && (
        <AssetActionModal
          open={!!selectedAsset}
          modalType={actionModal}
          data={selectedAsset}
          onClose={handleAssetActionClose}
          onSubmit={handleAssetActionSubmit}
        />
      )}
      {openAppealModal && (
        <BrandAssetAppealModal
          open={openAppealModal}
          onClose={() => setOpenAppealModal(false)}
          onSubmit={() => handleSubmitAppeal()}
        />
      )}
    </Box>
  );
};

export default BrandAssetsList;
