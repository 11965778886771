import {
  BoxV2 as Box,
  Flex,
  RoleGuard,
  useHasPermission,
} from 'portal-commons';
import { FunctionComponent, MouseEventHandler, useRef, useState } from 'react';
import {
  faEllipsisVertical,
  faHourglass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@material-ui/core';

import BrandAssetList, {
  BrandAssetListHeader,
  BrandAssetRow,
} from '../BrandAssetList';
import { BrandAsset } from '../../types';

const Headers: BrandAssetListHeader[] = [
  {
    id: 'name',
    label: 'LOGO',
  },
  {
    id: 'status',
    label: 'STATUS',
    width: 105,
  },
  {
    id: 'action',
    label: 'ACTION',
    width: 100,
  },
];

const ReadonlyHeaders: BrandAssetListHeader[] = [
  {
    id: 'name',
    label: 'LOGO',
  },
  {
    id: 'status',
    label: 'STATUS',
    width: 105,
  },
];

interface BrandLogoListProps {
  logos: BrandAsset[];
  onSelect?: (index: number) => void;
  onVerify?: (index: number) => void;
}

interface MenuButtonProps {
  onVerify?: () => void;
}

interface RowProps {
  logo: BrandAsset;
  onSelect?: () => void;
  onVerify?: () => void;
}

const MenuButton: FunctionComponent<MenuButtonProps> = ({ onVerify }) => {
  const [anchorElement, setAnchorElement] = useState<Element>();

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleBlur = () => {
    setAnchorElement(undefined);
  };

  const handleVerify: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    onVerify?.();
    setAnchorElement(undefined);
  };

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 16,
        height: 16,
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
      <Menu
        className="tcr-menu"
        disableAutoFocusItem
        open={!!anchorElement}
        anchorEl={anchorElement}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBlur={handleBlur}
      >
        <MenuItem onClick={handleVerify}>Verify</MenuItem>
      </Menu>
    </Flex>
  );
};

const Row: FunctionComponent<RowProps> = ({ logo, onSelect, onVerify }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleNameClick = () => {
    inputRef.current?.click();
  };

  return (
    <BrandAssetRow>
      <Flex
        sx={{
          columnGap: '7px',
          color: '#0091B3',
          fontSize: '14px',
          lineHeight: '16px',
        }}
      >
        <input
          ref={inputRef}
          style={{ accentColor: '#D41C62', cursor: 'pointer' }}
          type="radio"
          name="logo"
          onClick={onSelect}
        />
        <Box sx={{ cursor: 'pointer' }} onClick={handleNameClick}>
          {logo.name}
        </Box>
      </Flex>
      <Flex sx={{ columnGap: '7px' }}>
        {logo.vettingStatus?.toLowerCase() === 'pending' && (
          <FontAwesomeIcon icon={faHourglass} size="sm" />
        )}
        {logo.vettingStatus ?? 'N/A'}
      </Flex>
      <RoleGuard feature="brandDetail.verifyAsset">
        <Flex sx={{ color: '#D41C62' }}>
          <MenuButton onVerify={onVerify} />
        </Flex>
      </RoleGuard>
    </BrandAssetRow>
  );
};

const BrandLogoList: FunctionComponent<BrandLogoListProps> = ({
  logos,
  onSelect,
  onVerify,
}) => {
  const authorized = useHasPermission('brandDetail.verifyAsset');
  return (
    <BrandAssetList headers={authorized ? Headers : ReadonlyHeaders}>
      {logos.map((logo, index) => (
        <Row
          key={logo.attachmentUuid}
          logo={logo}
          onSelect={() => onSelect?.(index)}
          onVerify={() => onVerify?.(index)}
        />
      ))}
    </BrandAssetList>
  );
};

export default BrandLogoList;
