import { Box, Table } from 'portal-commons';
import { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { Link } from 'react-router-dom';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Grid } from '@material-ui/core';
import { RoleGuard } from 'portal-commons';

import {
  getBrandStatistics,
  getCampaignDetail,
  getCampaignByPhone,
  getCampaignStatistics,
  getCampaignTopUseCase,
  getEventHistory,
  getPartnerCampaignStatistics,
} from '../apiServices';
import { EventTableRow, UseCaseTableRow, SearchTextField } from '../components';
import { S3_ASSETS_PATH } from '../../../../constants';
import {
  CNP_CAMPAIGNS_DEFAULT_ENTRY,
  MY_CAMPAIGNS_DEFAULT_ENTRY,
} from '../../../../constants/paths';
import { withAuthContext } from '../../../../contexts';
import { GeneralAlertPopup } from '../../../../shared_elements';
import { fieldValidation } from '../../../../utils/validator';

import '../../../../assets/styles/dashboard-new-module.scss';

const option = {
  justification: 'C',
  locales: 'en-AU',
  percentage: false,
  wholenumber: null,
  commafy: true,
  shortFormat: true,
  shortFormatMinValue: 10000,
  shortFormatPrecision: 2,
  title: true,
  cssClass: ['red'],
};

class DashboardNew extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      brandInfo: {},
      campaignInfo: {},
      eventInfo: {
        records: [],
      },
      useCaseInfo: {},
      partnerCampaignInfo: {},
      tableLoader: {
        event: true,
        usecase: true,
      },
      genAlertFlag: false,
      campaignSearchValue: '',
      campaignSearchError: '',
      campaignSearching: false,
      errorCode: {
        id: {
          0: '',
          2: 'Minimum 7 characters id',
          3: 'Maximum 7 characters id',
          4: 'Invalid id',
        },
        idObj: {
          regexPattern: /^[a-zA-Z0-9]{7}$/,
          maxLength: 7,
          minLength: 7,
        },
        phone: {
          0: '',
          2: 'Minimum 10 digits phone number',
          4: 'Invalid phone number',
        },
        phoneObj: {
          // refer to https://campaignreg.atlassian.net/wiki/x/CICuC
          regexPattern:
            /^\+?(?:[0-9][_!#$%&'*+\/=?`{|}~^.\-()\s]*?){6,14}[0-9]$/,
          minLength: 10,
        },
      },
    };
    this.getBrandStatistics = getBrandStatistics.bind(this);
    this.getCampaignStatistics = getCampaignStatistics.bind(this);
    this.getCampaignTopUseCase = getCampaignTopUseCase.bind(this);
    this.getEventHistory = getEventHistory.bind(this);
    this.getPartnerCampaignStatistics = getPartnerCampaignStatistics.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.title = 'The Campaign Registry - Dashboard';
    this.getBrandStatistics();
    this.getCampaignStatistics();
    this.getCampaignTopUseCase();
    this.getPartnerCampaignStatistics();
    this.getEventHistory({
      sortField: 'createDate',
      ascendingOrder: false,
      recordsPerPage: 15,
    });
    if (
      this.props.profile &&
      this.props.profile.status.toLowerCase() === 'active'
    ) {
      if (localStorage.getItem('generalAlert')) {
        this.setState({ genAlertFlag: true });
        localStorage.removeItem('generalAlert');
      }
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUseCases = () => {
    const { useCaseInfo } = this.state;
    if (useCaseInfo) {
      return Object.keys(useCaseInfo)
        .sort(function (a, b) {
          return useCaseInfo[b] - useCaseInfo[a];
        })
        .map((key) => {
          return { name: key, count: useCaseInfo[key] };
        });
    }
    return [];
  };

  isIdPattern = (value) => {
    const isIdPattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9]*$/.test(value);
    return isIdPattern;
  };

  validator = (allowEmpty = true) => {
    const { campaignSearchValue, errorCode } = this.state;
    if (!allowEmpty && !campaignSearchValue) {
      return 'This field is required';
    }
    let errorMsg = '';
    if (this.isIdPattern(campaignSearchValue)) {
      errorMsg =
        errorCode.id[
          fieldValidation({
            ...errorCode.idObj,
            fieldval: campaignSearchValue,
          })
        ];
    } else {
      errorMsg =
        errorCode.phone[
          fieldValidation({
            ...errorCode.phoneObj,
            fieldval: campaignSearchValue,
          })
        ];
    }
    return errorMsg;
  };

  handleError = () => {
    const errorMsg = this.validator();
    this.setState((prevState) => ({
      ...prevState,
      campaignSearchError: errorMsg,
    }));
  };

  handleSearchChange = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      campaignSearchValue: value,
      campaignSearchError: '',
    }));
  };

  handleSearchClick = async () => {
    const { campaignSearchValue } = this.state;
    this.setState({ campaignSearching: true });
    const errorMsg = this.validator(false);
    if (!errorMsg) {
      const response = this.isIdPattern(campaignSearchValue)
        ? await getCampaignDetail(campaignSearchValue)
        : await getCampaignByPhone(campaignSearchValue);
      this.setState({ campaignSearching: false });
      if (response) {
        this.props.history.push({
          pathname: `/campaigns/${response.uid}`,
          state: { goBackPage: this.props.location.pathname },
        });
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        campaignSearchError: errorMsg,
        campaignSearching: false,
      }));
    }
  };

  render() {
    const {
      brandInfo,
      campaignInfo,
      eventInfo,
      genAlertFlag,
      tableLoader,
      partnerCampaignInfo,
      campaignSearchValue,
      campaignSearchError,
      campaignSearching,
    } = this.state;

    return (
      <section className="dashboard-section" data-testid="dashboard">
        <Container maxWidth={false} className="dashboard-container">
          <Grid container spacing={3} direction="row-reverse">
            <Grid item xs={5} justifyContent="flex-end">
              <SearchTextField
                label="Search by Campaign ID or Phone Number"
                value={campaignSearchValue}
                error={campaignSearchError}
                searching={campaignSearching}
                handleChange={this.handleSearchChange}
                handleError={this.handleError}
                handleSearchClick={this.handleSearchClick}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div className="statistics-wrapper">
                <div
                  className="count-wrapper"
                  data-testid="dashboardActiveCampaignsButton"
                  onClick={() => {
                    this.props.history.push(MY_CAMPAIGNS_DEFAULT_ENTRY);
                  }}
                >
                  <p className="paragraph">Active Campaigns</p>
                  <div
                    className="count"
                    data-testid="dashboardActiveCampaignsCount"
                  >
                    {campaignInfo.ACTIVE != 'undefined' &&
                    campaignInfo.ACTIVE != null ? (
                      <NumericLabel params={option}>
                        {campaignInfo.ACTIVE}
                      </NumericLabel>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
                <RoleGuard feature="campaignList.addCampaign">
                  <p data-testid="dashboardAddNewCampaignButton">
                    <Link to="/campaign/create" className={'primary-btn'}>
                      +Add new Campaign
                    </Link>
                  </p>
                </RoleGuard>
                <img
                  src={`${S3_ASSETS_PATH}/images/dashboard-campaign.svg`}
                  className="campaigns bg-img"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="statistics-wrapper brand">
                <div
                  data-testid="dashboardActiveBrandButton"
                  className="count-wrapper"
                  onClick={() => {
                    this.props.history.push('/brands');
                  }}
                >
                  <p className="paragraph">Active Brands</p>
                  <div
                    className="count"
                    data-testid="dashboardActiveBrandCount"
                  >
                    {brandInfo.total != 'undefined' &&
                    brandInfo.total != null ? (
                      <NumericLabel params={option}>
                        {brandInfo.total}
                      </NumericLabel>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
                <RoleGuard feature="brandList.addBrand">
                  <p data-testid="dashboardActiveBrandButton">
                    <Link to="/brand/create" className={`primary-btn`}>
                      +Add new Brand
                    </Link>
                  </p>
                </RoleGuard>
                <img
                  src={`${S3_ASSETS_PATH}/images/dashboard-brand.svg`}
                  className="bg-img"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="statistics-wrapper partner-campaigns">
                <div
                  data-testid="dashboardPendingPartnerCampaignsButton"
                  className="count-wrapper"
                  onClick={() => {
                    this.props.history.push(CNP_CAMPAIGNS_DEFAULT_ENTRY);
                  }}
                >
                  <p className="paragraph">
                    Pending Connectivity Partner Campaigns
                  </p>
                  <div
                    className="count"
                    data-testid="dashboardPendingPartnerCampaignsCount"
                  >
                    {partnerCampaignInfo.total != 'undefined' &&
                    partnerCampaignInfo.total != null ? (
                      <NumericLabel params={option}>
                        {partnerCampaignInfo.total}
                      </NumericLabel>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
                <RoleGuard
                  feature="cnpCampaignList.view"
                  render={(authorized) => (
                    <p data-testid="dashboardPendingPartnerCampaignsButton">
                      <Link
                        to={CNP_CAMPAIGNS_DEFAULT_ENTRY}
                        className={
                          authorized ? 'primary-btn' : 'primary-btn disabled'
                        }
                      >
                        View
                      </Link>
                    </p>
                  )}
                />
                <img
                  src={
                    require(`../../../../assets/images/partner-campaigns-icon.svg`)
                      .default
                  }
                  className="bg-img"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <div className="events-listing wrapper">
                    <div className="titleRow">
                      <h3
                        className="heading1 text-center"
                        data-testid="dashboardEventsViewAllButton"
                      >
                        <Box
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box></Box>
                          <Box flexDirection="row" alignItems="center">
                            <Box margin={{ right: 'xs' }}>
                              <FontAwesomeIcon icon={faCalendarDay} />
                            </Box>
                            <span>Events</span>
                          </Box>
                          <Box>
                            <Link to="/events" className="primary-btn">
                              View all
                            </Link>
                          </Box>
                        </Box>
                      </h3>
                    </div>
                    <Table
                      className="events-listing-table"
                      data-testid="eventListingTable"
                      disableHover
                      headRows={[
                        {
                          id: 'datetime',
                          label: 'DATE & TIME',
                          sortable: false,
                        },
                        {
                          id: 'description',
                          label: 'DESCRIPTION',
                          sortable: false,
                        },
                      ]}
                      records={{ total: eventInfo.records.length }}
                      loading={tableLoader.event}
                      emptyState="no events to view"
                      tableData={eventInfo?.records?.map((event) => (
                        <EventTableRow data={event} key={event.id} />
                      ))}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="usecase-listing wrapper">
                        <div className="titleRow">
                          <h3 className="heading1 text-center">Use-cases</h3>
                        </div>
                        <Table
                          className="use-cases-table"
                          data-testid="dashboardTableCampaign"
                          disableHover
                          headRows={[
                            {
                              id: 'name',
                              label: 'USE-CASES',
                              sortable: false,
                            },
                            {
                              id: 'count',
                              label: 'No. OF CAMPAIGNS',
                              sortable: false,
                            },
                          ]}
                          records={{ total: this.getUseCases().length }}
                          tableData={this.getUseCases().map((d) => (
                            <UseCaseTableRow data={d} key={d.name} />
                          ))}
                          loading={tableLoader.usecase}
                          emptyState="no use-cases to view"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {
          <GeneralAlertPopup
            open={genAlertFlag}
            handleClose={() => this.setState({ genAlertFlag: false })}
          />
        }
      </section>
    );
  }
}

export default withAuthContext(DashboardNew);
